/* .btn-cls {
  background-color: #00984c !important;
  
} */
* {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
}
:root {
  --login-card-color: #00984c;
  --login-background-color: #fffff7;
  --login-btn-color: #e67c18;
}
iframe {
  display: none !important;
}
.search-cls {
  margin-top: 8px;
}
.btn-css {
  cursor: "pointer" !important;
  background-color: "transparent" !important;
  box-shadow: "none" !important;
}
.icon-css {
  color: "#00984c" !important;
}

.margin-top-cls {
  margin-top: 15px !important;
}
.record-not-found {
  text-align: center !important;
  margin-top: 20px !important;
}
.bg-white {
  background-color: #00984c !important;
}

.bg-white-original {
  background-color: #fff !important;
}

.header-dark-green {
  color: var(--login-background-color) !important;
}

.login-card {
  background-color: var(--login-card-color) !important;
}

.bg-light-original {
  background-color: var(--login-card-color) !important;
}

.login-btn {
  position: relative !important;
  text-transform: none !important;
  transition: all 0.15s ease !important;
  letter-spacing: 0.025em !important;
  font-size: 0.875rem !important;
  will-change: transform !important;
  background-color: #fff !important;
  border: none !important;
}

.sign-up-btn {
  position: relative !important;
  text-transform: none !important;
  transition: all 0.15s ease !important;
  letter-spacing: 0.025em !important;
  font-size: 0.875rem !important;
  will-change: transform !important;
  background-color: var(--login-btn-color) !important;
  border: none !important;
}
.card {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  /* background-color: #fff !important; */
  background-clip: border-box !important;
  border: none !important;
  border-radius: 0.375rem !important;
}

h3,
.h3 {
  margin-bottom: 0.5rem !important;
  font-family: inherit !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #fff !important;
}

.bg-white-sidebar {
  background-color: #fff !important;
}

.close > span:not(.sr-only) {
  background-color: transparent !important;
  color: #fff !important;
  line-height: 17px !important;
  height: 1.25rem !important;
  width: 1.25rem !important;
  border-radius: 50% !important;
  font-size: 1.25rem !important;
  display: block !important;
  transition: all 0.15s ease !important;
}
.h3-old {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.sweet-btn {
  color: #fff;
  background-color: #32325d;
}

.swal-modal {
  width: 350px !important;
}

.card-border {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.375rem !important;
}
.dropdown-border {
  border: 1px solid !important;
  border-radius: 0.375rem !important;
}
.status-filter {
  /* border: 1.8px solid;
  box-shadow: none !important; */
  display: block !important;
  /* width: 100% !important; */
  height: calc(1em + 1.25rem + 2px) !important;
  padding: 0.425rem 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #8898aa !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  /* border: 1px solid #cad1d7 !important; */
  border-radius: 0.375rem !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00984c;
  color: #00984c;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00984c;
  color: #00984c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00984c;
  color: #00984c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #00984c;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.borderless {
  border: 0px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  /* padding: 0px !important; */
}

.css-jfeqr5 {
  box-sizing: border-box !important;
  max-height: 100% !important;
  max-width: 100% !important;
  overflow: hidden !important;
  padding: 8px !important;
  position: fixed !important;
  z-index: 2000 !important;
  top: 0px !important;
  right: 0px !important;
}
.search-trans-cls {
  min-height: 400px;
  background-color: #fff;
}

.card-header-border {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important; */
}

.center-div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* padding: 25px; */
  margin-left: 15%;
  margin-right: 15%;
  min-height: 240px;
}

.commission-table-cls {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  font-size: 0.65 !important;
  text-transform: uppercase !important;
  letter-spacing: 0px !important;
  border-bottom: 1px solid #e9ecef !important;
  border-left: 1px solid #e9ecef !important;
  border-right: 1px solid #e9ecef !important;
}

.commission-table-cls-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  font-size: 0.65 !important;
  letter-spacing: 0px !important;
  border-bottom: 1px solid #e9ecef !important;
  border-left: 1px solid #e9ecef !important;
  border-right: 1px solid #e9ecef !important;
}
.valuePadding {
  border: 1px inset #ccc;
}
.valuePadding input {
  /* border: none; */
  padding: 0px;
  outline: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eff6fb !important;
  opacity: 1 !important;
}

.chart-card {
  min-height: 200px;
  height: 350px;
}

.paginationV2 {
  /* margin: 15px auto; */
  display: flex;
  list-style: none;
  outline: none;
}
.paginationV2 > .active > a {
  background-color: #e67c18;
  border-color: #e67c18;
  color: #fff;
}
.paginationV2 > li > a {
  border: 0.2px solid #e67c18;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}
.paginationV2 > .active > a,
.paginationV2 > .active > span,
.paginationV2 > .active > a:hover,
.paginationV2 > .active > span:hover,
.paginationV2 > .active > a:focus,
.paginationV2 > .active > span:focus {
  background-color: #e67c18;
  border-color: #e67c18;
  outline: none;
}
.paginationV2 > li > a,
.paginationV2 > li > span {
  color: #e67c18;
}
.paginationV2 > li:first-child > a,
.paginationV2 > li:first-child > span,
.paginationV2 > li:last-child > a,
.paginationV2 > li:last-child > span {
  border-radius: unset;
}
.react-tel-input .form-control {
  position: relative !important;
  font-size: 14px !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  /* background:#FFFFFF !important; */
  border: 1px solid #cacaca !important;
  border-radius: 5px !important;
  line-height: 25px !important;
  height: calc(1.5em + 1.25rem + 2px) !important;
  width: 100% !important;
  outline: none !important;
}

.small-btn {
  display: inline-block !important;
  font-weight: 600 !important;
  color: #525f7f !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  padding: 0.625rem 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.375rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.chart-card-header {
  font-size: 1rem !important;
}

.bg-dark-green {
  background-color: #fff !important;
}
.dark-color {
  color: var(--login-background-color) !important;
}

.link-cls {
  color: var(--login-background-color) !important;
  text-decoration: none;
  background-color: transparent;
}

.bg-preview {
  background-color: var(--login-card-color);
}

.bg-gradient-info {
  background: linear-gradient(
    87deg,
    var(--login-card-color) 0,
    #e67c18 100%
  ) !important;
  /* background: linear-gradient( 180deg,#94dbfc 10%,#021f4a 90%) !important; */
}
/* .center-inner-div {
  
} */

/* [data-toggle="collapse"] i:before{
  content: "\f0d8";
}

[data-toggle="collapse"].collapsed i:before {
  content: "\f0d7";
} */

[data-toggle="collapse"] .fas:before {
  content: "\f0d7"; /* down arrow*/
}

[aria-expanded="true"] .fas:before {
  content: "\f0d8"; /* up arrow */
}

[data-toggle="collapse"].collapsed .fas:before {
  content: "\f0d7"; /* down arrow*/
}

.logs-search-cls {
  display: block !important;
  /* width: 100% !important; */
  height: calc(1em + 1.25rem + 2px) !important;
  /* padding: 0.425rem 0.75rem !important; */
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  /* line-height: 1.5 !important; */
  color: #8898aa !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  /* border: 1px solid #cad1d7 !important; */
  border-radius: 0.375rem !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}

.popover-header-cls {
  margin-bottom: 0.5rem !important;
  font-family: inherit !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: rgb(50, 45, 73) !important;
}

.logout-modal {
  position: relative !important;
  box-sizing: border-box !important;
  grid-template-columns: minmax(0, 100%) !important;
  width: 22em !important;
  max-width: 100% !important;
  padding: 0 0 1.25em !important;
  border: none !important;
  border-radius: 5px !important;
  background: #fff !important;
  color: #545454 !important;
  font-family: inherit !important;
  font-size: 0.8rem !important;
}

.pending-settlements-report-summary-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pending-settlements-report-summary-inner {
  width: 200px;
}

.pending-settlements-report-summary-heading {
  font-weight: 800;
  margin-bottom: 20px;
}
